<template>
  <v-card
    flat
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="break-word"
        >
          {{ formattedItem.title }} ({{ formattedItem.pregunta_checklist_ot.alias }})
          <span
            v-if="formattedItem.pregunta_checklist.observaciones_internas"
            class="more-info info white--text"
            @click="clickPreguntaObservacionesInternas(formattedItem)"
          >
            +info
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-card-subtitle
      class="py-1"
    >
      <v-row
        no-gutters
        class="d-flex justify-space-between"
      >
        <div
          class="d-flex align-center"
        >
          <v-btn-toggle
            v-model="gradoAnomaliaClasificacion"
            borderless
          >
            <!-- OK -->
            <v-btn
              icon
              color="white"
              @click.stop="clickOk()"
            >
              <v-icon
                :color="colorBotonOk"
              >
                {{ $vuetify.icons.values.preguntaChecklistOk }}
              </v-icon>
            </v-btn>
            <!-- CON ANOMALÍAS -->
            <v-badge
              v-model="formattedItem.conMultiplesAnomalias"
              overlap
              color="red"
              :content="formattedItem.cantAnomalias"
            >
              <v-btn
                icon
                color="white"
                @click.stop="clickConAnomalias"
              >
                <v-icon
                  :color="colorBotonConAnomalias"
                >
                  {{ $vuetify.icons.values.preguntaChecklistMal }}
                </v-icon>
              </v-btn>
            </v-badge>
            <!-- NO PROCEDE -->
            <boton-pregunta-desplegable
              v-if="gradosAnomaliaNoProcede.length > 0"
              :grados-anomalia="gradosAnomaliaNoProcede"
              :color-boton="colorBotonNoProcede"
              :icon-boton="$vuetify.icons.values.preguntaChecklistNoProcede"
              :readonly="!estadoChecklistPendiente"
              @click="clickNoProcede"
            />
            <!-- DESCONOCIDO -->
            <boton-pregunta-desplegable
              v-if="gradosAnomaliaDesconocido.length > 0"
              :grados-anomalia="gradosAnomaliaDesconocido"
              :color-boton="colorBotonDesconocido"
              :icon-boton="$vuetify.icons.values.preguntaChecklistDesconocido"
              :readonly="!estadoChecklistPendiente"
              @click="clickDesconocido"
            />
          </v-btn-toggle>
          <v-chip
            v-show="colorLabel"
            label
            small
            :color="colorLabel"
            dark
            class="ml-2"
          >
            {{ formattedItem.grado_anomalia.alias }}
          </v-chip>
        </div>
        <v-btn-toggle
          borderless
        >
          <v-badge
            v-if="preguntaConValorParaCapturar"
            v-model="conValorCapturado"
            overlap
            dot
            color="red"
          >
            <v-btn
              icon
              color="white"
              @click="clickValor"
            >
              <v-icon
                color="grey"
              >
                {{ $vuetify.icons.values.preguntaChecklistValores }}
              </v-icon>
            </v-btn>
          </v-badge>
          <v-badge
            v-model="formattedItem.conNotasInternasObservaciones"
            overlap
            dot
            color="red"
          >
            <v-btn
              icon
              color="white"
              @click="clickNotasInternasObservaciones"
            >
              <v-icon
                color="grey"
              >
                {{ $vuetify.icons.values.chat }}
              </v-icon>
            </v-btn>
          </v-badge>
        </v-btn-toggle>
      </v-row>
    </v-card-subtitle>
    <v-card-subtitle
      v-if="!!formattedItem.observaciones"
      class="py-1"
    >
      <v-alert
        :icon="$vuetify.icons.values.chat"
        color="warning"
        dark
        dense
      >
        <template #close="{ toggle }">
          <v-icon
            @click="clickBorrarObservaciones(toggle)"
          >
            {{ $vuetify.icons.values.delete }}
          </v-icon>
        </template>
        {{ formattedItem.observaciones }}
      </v-alert>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import _ from '@/utils/lodash'
import { GRADO_ANOMALIA, PREGUNTA_CHECKLIST } from '@/utils/consts'
import BotonPreguntaDesplegable from './BotonPreguntaDesplegable'
import colors from 'vuetify/lib/util/colors'

export default {
  components: {
    BotonPreguntaDesplegable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    estadoChecklistPendiente: {
      type: Boolean,
      default: false,
    },
    gradoAnomaliaCorrecto: {
      type: Object,
      default: () => {},
    },
    gradosAnomaliaNoProcede: {
      type: Array,
      default: () => [],
    },
    gradosAnomaliaDesconocido: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      PREGUNTA_CHECKLIST,
      mensajeErrorChecklistFinalizado: 'No es posible modificar un checklist finalizado',
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.observaciones = item.pregunta_checklist_ot.observaciones
      item.title = item.pregunta_checklist_ot.descripcion
      item.conMultiplesAnomalias = item.cantAnomalias > 1
      item.conNotasInternasObservaciones = !!item.pregunta_checklist_ot.notas_internas || !!item.pregunta_checklist_ot.observaciones
      return item
    },
    gradoAnomaliaClasificacion: {
      get () {
        if (this.item.pregunta_checklist_ot.idgrado_anomalia === null) {
          return null
        } else if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.correcto) {
          return PREGUNTA_CHECKLIST.resultado.ok
        } else if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.noProcede) {
          return PREGUNTA_CHECKLIST.resultado.noProcede
        } else if (
          this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.incorrecto ||
          this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.desconocido
        ) {
          return PREGUNTA_CHECKLIST.resultado.conAnomalias
        } else {
          return null
        }
      },
      set () {
        // no hacer nada, pero evitar error "has no setter" -> esta como v-model del btn-toggle
      },
    },
    colorBotonOk () {
      if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.correcto) {
        return PREGUNTA_CHECKLIST.colorResultado.ok
      } else {
        return colors.grey.base
      }
    },
    colorBotonNoProcede () {
      if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.noProcede) {
        return PREGUNTA_CHECKLIST.colorResultado.noProcede
      } else {
        return colors.grey.base
      }
    },
    colorBotonDesconocido () {
      if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.desconocido) {
        return PREGUNTA_CHECKLIST.colorResultado.conAnomalias
      } else {
        return colors.grey.base
      }
    },
    colorBotonConAnomalias () {
      if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.incorrecto) {
        return PREGUNTA_CHECKLIST.colorResultado.conAnomalias
      } else {
        return colors.grey.base
      }
    },
    colorLabel () {
      if (this.item.pregunta_checklist_ot.idgrado_anomalia === null) {
        return ''
      } else if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.correcto) {
        return PREGUNTA_CHECKLIST.colorResultado.ok
      } else if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.noProcede) {
        return PREGUNTA_CHECKLIST.colorResultado.noProcede
      } else if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.incorrecto) {
        return PREGUNTA_CHECKLIST.colorResultado.conAnomalias
      } else if (this.item.grado_anomalia.clasificacion === GRADO_ANOMALIA.clasificaciones.desconocido) {
        return PREGUNTA_CHECKLIST.colorResultado.conAnomalias
      } else  {
        return ''
      }
    },
    preguntaConValorParaCapturar () {
      return (
        this.item.pregunta_checklist_ot.clasificacion !== PREGUNTA_CHECKLIST.clasificacionValor.sinValor ||
        this.item.pregunta_checklist_ot.idagrupacion_pregunta_ot
      )
    },
    conValorCapturado () {
      return (
        this.item.pregunta_checklist_ot.valor !== null &&
        this.item.pregunta_checklist_ot.valor !== '' &&
        typeof(this.item.pregunta_checklist_ot.valor) !== 'undefined'
      ) || (
        !!this.item.valoresColumna &&
        this.item.valoresColumna.length > 0 &&
        _.findIndex(this.item.valoresColumna, (valorColumna) => {
          return valorColumna.valor !== null &&
            valorColumna.valor !== '' &&
            typeof(valorColumna.valor) !== 'undefined'
        }) >= 0
      )
    },
  },
  methods: {
    clickOk () {
      if (this.estadoChecklistPendiente) {
        this.$emit('click-grado-anomalia-resto', { item: this.item, index: this.index, gradoAnomalia: this.gradoAnomaliaCorrecto })
      } else {
        this.$alert.showSnackbarError(this.mensajeErrorChecklistFinalizado)
      }
    },
    clickConAnomalias () {
      if (this.item.cantAnomalias > 0) {
        this.$emit('click-anomalias', { item: this.item, index: this.index })
      } else {
        if (this.estadoChecklistPendiente) {
          this.$emit('click-grado-anomalia-error', { item: this.item, index: this.index })
        } else {
          this.$alert.showSnackbarError(this.mensajeErrorChecklistFinalizado)
        }
      }
    },
    clickNoProcede (gradoNoProcede) {
      if (this.estadoChecklistPendiente) {
        if (this.gradosAnomaliaNoProcede.length === 1) {
          this.$emit('click-grado-anomalia-resto', { item: this.item, index: this.index, gradoAnomalia: this.gradosAnomaliaNoProcede[0] })
        } else {
          this.$emit('click-grado-anomalia-resto', { item: this.item, index: this.index, gradoAnomalia: gradoNoProcede })
        }
      } else {
        this.$alert.showSnackbarError(this.mensajeErrorChecklistFinalizado)
      }
    },
    clickDesconocido (gradoDesconocido) {
      if (this.estadoChecklistPendiente) {
        if (this.gradosAnomaliaDesconocido.length === 1) {
          this.$emit('click-grado-anomalia-resto', { item: this.item, index: this.index, gradoAnomalia: this.gradosAnomaliaDesconocido[0] })
        } else {
          this.$emit('click-grado-anomalia-resto', { item: this.item, index: this.index, gradoAnomalia: gradoDesconocido })
        }
      } else {
        this.$alert.showSnackbarError(this.mensajeErrorChecklistFinalizado)
      }
    },
    clickAnomalias () {
      this.$emit('click-anomalias', { item: this.item, index: this.index })
    },
    clickValor () {
      this.$emit('click-valor', { item: this.item, index: this.index })
    },
    clickNotasInternasObservaciones () {
      this.$emit('click-notas-internas-observaciones', { item: this.item, index: this.index })
    },
    clickPreguntaObservacionesInternas (item) {
      this.$alert.showDialogInfo(item.pregunta_checklist.observaciones_internas)
    },
    async clickBorrarObservaciones(){
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar las observaciones de la pregunta ${this.item.pregunta_checklist_ot.alias}?`)
      if (res){
        this.$emit('click-borrar-observaciones', this.item.pregunta_checklist_ot.idpregunta_checklist_ot)
        this.formattedItem.observaciones = null
      }
    }
  }
}
</script>

<style scoped>
.more-info {
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-size: 0.9rem;
}
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
