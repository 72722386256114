import { render, staticRenderFns } from "./PreguntaListItem.vue?vue&type=template&id=660ee998&scoped=true&"
import script from "./PreguntaListItem.vue?vue&type=script&lang=js&"
export * from "./PreguntaListItem.vue?vue&type=script&lang=js&"
import style0 from "./PreguntaListItem.vue?vue&type=style&index=0&id=660ee998&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660ee998",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBadge,VBtn,VBtnToggle,VCard,VCardSubtitle,VChip,VIcon,VListItem,VListItemContent,VListItemTitle,VRow})
